import React from "react";
import downarrow from "../assets/careers_aboutus/downarrow.png";
import metric from "../assets/metrics.mp4";
import manageusers from "../assets/manage-users.mp4";
import reporting from "../assets/reportingsdg.mp4";
import impact from "../assets/impact-framework.mp4";
import datacollection from "../assets/data-collection.mp4";
import auditlog from "../assets/audit.mp4";
import { motion } from "framer-motion";
function ProductInvestors() {
  return (
    <div className="min-h-screen md:pt-44 pt-20 px-4">
      <div className="flex flex-col justify-center items-center mx-auto gap-10">
        <h1 className="font-semibold md:text-3xl text-xl max-w-4xl text-center">
          Tailored Impact Frameworks for Investors and Startup Ecosystem
        </h1>
        <h2 className="font-bold md:text-5xl text-3xl max-w-3xl md:text-center text-sustally-violet font-Poppins">
          Are you an impact investor or startup ecosystem enabler?
        </h2>

        <p className="md:text-3xl text-xl max-w-6xl md:text-center">
          Startups redefine the meaning of impact. Impact metrics vary across
          industries. With Sustally, you can track and present the metrics to
          your investors in a scientific way. Win trust and scale your portfolio
          companies
        </p>

        <motion.img
          animate={{ y: -20 }}
          transition={{
            duration: 0.8,
            repeat: Infinity,
            type: "spring",
            repeatDelay: 1,
            bounce: 0.3,
            mass: 0.8,
          }}
          src={downarrow}
          alt="down arrow"
          className="w-16 cursor-pointer"
          width={""}
          height={""}
        ></motion.img>
      </div>

      <div className="flex flex-col gap-10 mt-32">
        <div className="flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto">
          <div className="flex-1 h-full">
            <video
              loop
              className="rounded-2xl md:w-full w-[95%] h-full"
              autoPlay
              muted
              playsInline
              onContextMenu={(e) => e.preventDefault()}
            >
              <source src={metric} type="video/mp4" />
              Video about the metric feature in Sustally App.
            </video>
          </div>
          <div className="flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center">
            <p className="font-Poppins font-semibold md:text-3xl text-xl px-4 pt-10 text-sustally-violet md:pl-10">
              Define what is measurable now and scale it for your portfolio
            </p>
            <ul className="md:text-xl text-md font-Inter list-disc pb-10 pt-4 pl-8 md:pl-14 pr-4">
              <li>
                Start with just one metric for one company and scale it for
                entire portfolio
              </li>
              <li>
                Create your own impact metric library, customize for each
                startup
              </li>
              <li>
                Keep track of the changes made in your metric definition using
                audit trail
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-10 mt-32">
        <div className="flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto">
          <div className="flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center md:order-1 order-2">
            <p className="font-Poppins font-semibold md:text-3xl text-xl px-4 pt-10 text-sustally-violet max-w-xl">
              Design simple, unique, startup friendly frameworks
            </p>
            <ul className="md:text-xl text-lg font-Inter list-disc pb-10 pt-4 pl-8 md:pl-14 pr-4">
              <li>Create one framework for each portfolio or for each fund</li>
              <li>Cross utilize the frameworks between multiple portfolios</li>
              <li>
                Make use of Industry standard frameworks and templates curated
                by Sustally
              </li>
            </ul>
          </div>
          <div className="flex-1 h-full md:order-2 order-1">
            <video
              loop
              className="rounded-2xl w-[95%] h-full"
              autoPlay
              muted
              playsInline
              onContextMenu={(e) => e.preventDefault()}
            >
              <source src={impact} type="video/mp4" />
              Video about the metric feature in Sustally App.
            </video>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-10 mt-32">
        <div className="flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto">
          <div className="flex-1 h-full">
            <video
              loop
              className="rounded-2xl  w-[95%] h-full"
              autoPlay
              muted
              playsInline
              onContextMenu={(e) => e.preventDefault()}
            >
              <source src={reporting} type="video/mp4" />
              Video about the metric feature in Sustally App.
            </video>
          </div>
          <div className="flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center ">
            <p className="font-Poppins font-semibold md:text-3xl text-xl px-2 pt-10 text-sustally-violet md:pl-10">
              {" "}
              Integrate and view the summarized impact of your entire portfolio
            </p>
            <ul className="md:text-xl text-lg font-Inter list-disc pb-10 pt-4  w-full pl-8 md:pl-14 pr-4">
              <li>
                Integrate data across multiple geographies, companies using
                frameworks
              </li>
              <li>
                Control & modify the frameworks to suit regulatory changes from
                time to time
              </li>
              <li>Decide who views what, with enhanced data security</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-10 mt-32">
        <div className="flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto">
          <div className="flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center order-2 md:order-1">
            <p className="font-Poppins font-semibold md:text-3xl text-xl px-4 pt-10 text-sustally-violet max-w-xl">
              Take load away from founders, delegate across multiple users
            </p>
            <ul className="md:text-xl text-lg font-Inter list-disc pb-10 pt-4 pl-8 md:pl-14 pr-4">
              <li>Collect data from multiple users in each startup</li>
              <li>
                Access control and lock edit features based user permissions
              </li>
              <li>
                Integrate with excel or other software for seamless data import
              </li>
            </ul>
          </div>
          <div className="flex-1 h-full order-1 md:order-2">
            <video
              loop
              className="rounded-2xl w-[95%] h-full"
              autoPlay
              muted
              playsInline
              onContextMenu={(e) => e.preventDefault()}
            >
              <source src={datacollection} type="video/mp4" />
              Video about the metric feature in Sustally App.
            </video>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-10 mt-32">
        <div className="flex flex-col md:flex-row md:gap-20 gap-10 justify-center items-center mx-auto">
          <div className="flex-1 h-full">
            <video
              loop
              className="rounded-2xl  w-[95%] h-full"
              autoPlay
              muted
              playsInline
              onContextMenu={(e) => e.preventDefault()}
            >
              <source src={auditlog} type="video/mp4" />
              Video about the metric feature in Sustally App.
            </video>
          </div>
          <div className="flex flex-col flex-1 bg-secondary-light dark:bg-secondary-dark rounded-2xl h-full mx-auto justify-center items-center ">
            <p className="font-Poppins font-semibold md:text-3xl text-xl px-2 pt-10 text-sustally-violet md:pl-10">
              Build trust through the transparent source of data
            </p>
            <ul className="md:text-xl text-lg font-Inter list-disc pb-10 pt-4  w-full pl-8 md:pl-14 pr-4">
              <li>Use audit trail to track who modified what</li>
              <li>Publish live reports and dashboard in single click</li>
              <li>
                Provide multi-level timestamped data access to your auditors
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductInvestors;
